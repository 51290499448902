<template>
    <div class="email_task_list">
        <el-card>
            <!-- <nav class="input" style="width: auto">
                <el-input v-model="search.name" size="medium" placeholder="商品名" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
            </nav> -->
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="add">{{ "新建任务" }}</el-button>
            </nav>
        </el-card>

        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="taskName" label="任务名称"></el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{ row }">
                        <!-- <el-tag :type="status[row.status].type">{{ status[row.status].text }}</el-tag> -->
                        <el-tag type="info" v-if="row.status == 0">{{ "未开始" }}</el-tag>
                        <el-tag type="success" v-if="row.status == 1">{{ "开始" }}</el-tag>
                        <el-tag type="success" v-if="row.status == 2">{{ "异常" }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="sendTime" label="发送时间"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button type="text" icon="el-icon-edit" size="mini" @click="edit(row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" size="mini" class="red" style="color:red" @click="del(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                name: "",
                page: 1,
                pageSize: 10,
            },
            status: {
                0: {
                    type: "info",
                    text: "未开始",
                },
                1: {
                    type: "success",
                    text: "开始",
                },
            },
        };
    },
    created() {
        this.getlist();
    },
    methods: {
        add() {
            this.$router.push("/new_email_task_list");
        },
        async getlist() {
            let { data } = await this.$http.get("/admin/Email/getList", { params: this.search });
            this.list = data.data.list;
            this.total = data.data.totalCount;
        },
        subimt() {},
        handleCurrentChange(v) {
            this.search.page = v;
            this.getlist();
        },
        async del(val) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            this.$http.post("/admin/Email/del", { id: val.id }).then((res) => {
                //   console.log(res)
                if (res.data.errorCode == 200) {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    if (this.total % 10 == 1) {
                        this.search.page--;
                    }
                    this.getlist();
                }
            });
        },
        edit(val) {
            this.$router.push("/new_email_task_list?id=" + val.id);
        },
    },
};
</script>

<style lang="less" scoped></style>
